import React from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { useSelector } from 'react-redux';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import BarChartIcon from '@mui/icons-material/BarChart';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import AddchartIcon from '@mui/icons-material/Addchart';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import Diversity2Icon from '@mui/icons-material/Diversity2';
const WalletCards = () => {

    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    
    const fundingBal = useSelector((state) => state.wallet.fundingBalance);
    const subBal = useSelector((state) => state.wallet.subscriptionBalance);
    const refBal = useSelector((state) => state.wallet.referralBalance);
    const mainBal = useSelector((state) => state.wallet.withdrawableBalance);


  return (
    <div style={{marginTop: '75px'}}>
      <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

      <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <AccountBalanceWalletIcon />
                </ListItemIcon>
                <ListItemText primary={formatPrice(fundingBal)} secondary=
                {<Typography variant="caption" style={{fontSize: '11px'}} >
                    Account Balance
                </Typography>} 
                />
                </ListItemButton>
            </ListItem>     
        </Card>
    </Grid>

      <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <CandlestickChartIcon />
                </ListItemIcon>
                <ListItemText primary={formatPrice(mainBal)}secondary=
                {<Typography variant="caption" style={{fontSize: '11px'}} >
                    Spot Wallet
                </Typography>} 
                />
                </ListItemButton>
            </ListItem>   
        </Card>
    </Grid>

    <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <AddchartIcon />
                </ListItemIcon>
                <ListItemText primary={formatPrice(subBal)} secondary=
                {<Typography variant="caption" style={{fontSize: '11px'}} >
                    Trading Balance
                </Typography>} 
                 />
                </ListItemButton>
            </ListItem>     
        </Card>
    </Grid>

    {/* <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <SmartToyIcon />
                </ListItemIcon>
                <ListItemText primary={formatPrice(subBal)} secondary='Bots Trading Wallet'/>
                </ListItemButton>
            </ListItem>     
        </Card>
    </Grid> */}

    

    <Grid item xs={6} md={3}>
        <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
            <ListItem disablePadding>
                <ListItemButton>
                <ListItemIcon>
                    <Diversity2Icon />
                </ListItemIcon>
                <ListItemText primary={formatPrice(refBal)} secondary=
                {<Typography variant="caption" style={{fontSize: '11px'}} >
                    Referral Wallet
                </Typography>} 
                 />
                </ListItemButton>
            </ListItem>    
        </Card>
    </Grid>

    

    
    
</Grid>
    </div>
  )
}

export default WalletCards
