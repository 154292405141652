import React, {useEffect, useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import { Fragment } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {MiniChart } from "react-ts-tradingview-widgets";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WalletButton from '../Wallet/WalletButton';
import { useSelector } from 'react-redux';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';
import BalanceCard from '../Cards/BalanceCard';
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import { Timeline } from "react-ts-tradingview-widgets";


const CoinMarket = () => {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const coins = useSelector((state) => state.coins.coins);
    const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
    const availableCoins = ['USDT', 'USDTERC20', 'USDTTRC20', 'XMR', 'TRX', 'BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB'];
    const appMode = useSelector((state) => state.mode.mode);

  return (
    <div>
      
      <Card>
        <CardContent px={0}>
          <AdvancedRealTimeChart
            symbol= {`BINANCE:BTCUSDT`}
            interval= "1"
            timezone= "Etc/UTC"
            theme= {appMode}
            locale= "en"
            hide_top_toolbar= {true}
            enable_publishing= {false}
            save_image={false}
            hide_side_toolbar={true}
            backgroundColor={appMode === 'dark' ? '#1e1e1e' : '#ffffff'}
            isTransparent = {true}
            container_id= {1809}
            height={400}
            width="100%"
          >
         </AdvancedRealTimeChart>
         {appMode === 'dark'? 
            <Box sx={{height: '20px', backgroundColor: '#1e1e1e', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            
            </Box>
            :
            <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            
            </Box>
            }

          <Avatar sx={{display: 'absolute', zIndex: 10, transform: 'translate(10px, -170px)'}} alt="Payment icon" src="https://res.cloudinary.com/dg8pxsdme/image/upload/v1705500463/trustutilsdefi500_zqpbh7.png" />

         
         <Timeline colorTheme={appMode}
         isTransparent={true}
         theme= {appMode}
         feedMode="market"
         market="crypto"
         height={400}
         width="100%">
        </Timeline>

         {appMode === 'dark'? 
            <Box sx={{height: '20px', backgroundColor: '#1e1e1e', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            
            </Box>
            :
            <Box sx={{height: '20px', backgroundColor: 'white', transform: 'translate(0px, -25px)', position: 'relative', zIndex: 1}}>
            
            </Box>
            }
            
      </CardContent>
      </Card>
    </div>
  )
}

export default CoinMarket
