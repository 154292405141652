import React, {useState, useEffect, Fragment} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';
import Alert from '@mui/material/Alert';
import ListBeneficiary from './ListBeneficiary';

const KycVerification = () => {
    const [countries, setCountries] = useState([]);
    const [beneficiaries, setBeneficiary] = useState(null);
    const [usCitizen, setUsCitizen] = useState(false);
    const [myCountry, setMyCountry] = useState(null);

    const isUSA = (id) => {
        countries.forEach(country => {
            if (country.id === id) {
                if (country.name === 'United States of America') {
                    setUsCitizen(true) ;
                }else{
                    setUsCitizen(false) ;  
                }
            }
        });
    }
  
    const { isLoading: isFetchingCountry, mutate: loadCountry } = useMutation(        
        async () => {
          return await apiClient.get(`/api/load-beneficiaries`);
        },
        {
            onSuccess: (res) => {
                setCountries(res.data.countries);
                setBeneficiary(res.data.verifications);
                // localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                // const event = new Event('newMessage');
                // window.dispatchEvent(event);
                // const event2 = new Event('processed');
                // window.dispatchEvent(event2);
              },
              onError: (err) => {   
                if (err.response?.data.length) {
                    let myerror = err.response?.data || err;         
                setErros(myerror.errors) 

                
                }else{
                    let errorMessage = err.response?.data.message || err ;
                    localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                    const event = new Event('newMessage');
                    window.dispatchEvent(event);
                }   
              },
        }
      );
      
    const dispatch = useDispatch();
    
    const initialLogin = {
        first_name : ''	,
        last_name : ''	,
        phone_number : ''	,
        date_of_birth : ''	,
        residential_address: '',
        document_type : ''	,
        country: '',
        username: '',
        password: '',
        ssn: '',
        mothers_maiden_name: ''
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });

    const initialImage = {
        image: ''
    }
    const [FrontImage, setImage] = useState(initialImage);
    const  onFileChange = (event) => { 
        setImage({...FrontImage, image: event.target.files[0]});
      }; 


      const initialbackImage = {
        image: ''
    }
    const [backImage, setbackImage] = useState(initialbackImage);
    const  onBackFileChange = (event) => { 
        setbackImage({...backImage, image: event.target.files[0]});
      }; 

      const initialHandImage = {
        image: ''
    }
    const [handImage, setHandImage] = useState(initialHandImage);
    const  onHandFileChange = (event) => { 
        setHandImage({...handImage, image: event.target.files[0]});
      }; 

      const initialSsnFront = {
        image: ''
    }

    const [ssnFrontImage, setSsnFrontImage] = useState(initialSsnFront);
    const  onSsnFrontFileChange = (event) => { 
        setSsnFrontImage({...ssnFrontImage, image: event.target.files[0]});
      }; 


      const initialSsnBack = {
        image: ''
    }

    const [ssnBackImage, setSsnBackImage] = useState(initialSsnBack);
    const  onSsnBackFileChange = (event) => { 
        setSsnBackImage({...ssnBackImage, image: event.target.files[0]});
      }; 

      

      

    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
            const formData = new FormData();
            formData.append('country' , myCountry)
            formData.append('first_name' , planForm.first_name)
            formData.append('document_type' , planForm.document_type)
            formData.append('last_name' , planForm.last_name)
            formData.append('phone_number' , planForm.phone_number)
            formData.append('date_of_birth' , planForm.date_of_birth)
            formData.append('residential_address', planForm.residential_address)
            formData.append('username', planForm.username)
            formData.append('password', planForm.password)
            formData.append('ssn', planForm.ssn)
            formData.append('mothers_maiden_name', planForm.mothers_maiden_name)
            

            formData.append('front_document', FrontImage.image);
            formData.append('back_document', backImage.image);
            formData.append('hand_document', handImage.image);

            formData.append('ssn_back', ssnBackImage.image);
            formData.append('ssn_front', ssnBackImage.image);


          return await apiClient.post(`/api/kyc-verification`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
            setTimeout(() => {
                window.location.reload();
            }, 2000);
          },
          onError: (err) => {   
            
            if (err.response?.data.length) {
                let myerror = err.response?.data || err;         
            setErros(myerror.errors)     
            const event2 = new Event('processed');
            window.dispatchEvent(event2);          
            }else{
                let errorMessage = err.response?.data.message || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                const event2 = new Event('processed');
            window.dispatchEvent(event2);
            }   
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        loadCountry()
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        {(countries !== '' && countries.length > 0 ) ? 
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        KYC VERIFICATION
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to verify your identity, the most common documents used for proof of identity are government-issued identification documents like a passport, ID.me, driver's license, or national ID card
                    </Typography>

                    {(countries !== '' && countries.length > 0 )&&
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                        <InputLabel htmlFor="country">Select Country</InputLabel>
                        {(error !== '' && error.country) ?
                        <Select
                        error
                        onChange={(e)=> {setMyCountry(e.target.value)
                            isUSA(e.target.value)
                        }}
                        labelId="country"
                        id="country"
                        name="country"
                        label={`Select Country`}
                        helperText={error.country}
                        >
                        <MenuItem selected value=''>
                            Select Country
                        </MenuItem> 
                        {countries.map(country =>(
                            <MenuItem value={country.id}>
                                {country.name} </MenuItem>
                        ))}             
                        
                    </Select>
                        :
                        <Select
                        onChange={(e)=> {setMyCountry(e.target.value)
                            isUSA(e.target.value)
                        }}
                            labelId="country"
                            name="country"
                            id="country"
                            label={`Select Country`}
                            >
                            <MenuItem selected value="">
                                Select Country
                            </MenuItem>              
                            {countries.map(country =>(
                            <MenuItem value={country.id}>
                               
                                {country.name} </MenuItem>
                            ))} 
                        </Select>
                        }
                        <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {error !== '' && error.country}
                        </Typography>
                    </FormControl>
                        }
                    

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                      <InputLabel htmlFor="document_type">Document Type</InputLabel>
                      {(error !== '' && error.document_type) ?
                          <Select
                              error
                              onChange={onChange}
                              labelId="document_type"
                              id="document_type"
                              name="document_type"
                              label={`Document Type`}
                              helperText={error.document_type}
                              >
                              <MenuItem selected value=''>
                                  Select document type
                              </MenuItem>

                              <MenuItem selected value='passport'>
                              passport
                              </MenuItem> 
                              <MenuItem selected value="driver's license">
                              driver's license
                              </MenuItem> 
                              <MenuItem selected value='National ID Card'>
                              National ID Card
                              </MenuItem>              
                              
                          </Select>
                      :
                          <Select
                              onChange={onChange}
                              labelId="document_type"
                              name="document_type"
                              id="document_type"
                              label={`Document Type`}
                              >
                              <MenuItem selected value="">
                                  Select Document Type
                              </MenuItem>     

                              <MenuItem selected value='passport'>
                              Passport
                              </MenuItem> 
                              <MenuItem selected value="driver's license">
                              driver's license
                              </MenuItem> 
                              <MenuItem selected value='National ID Card'>
                              National ID Card
                              </MenuItem>    
                          </Select>
                      }
                      <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                      {error !== '' && error.document_type}
                      </Typography>
                  </FormControl>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="First Name">First Name</InputLabel>
                            {(error !== '' && error.first_name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="first_name"
                            name="first_name"
                            type={'text'}
                            label="First Name"
                            helperText={error.first_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="first_name"
                            name="first_name"
                            type={'text'}
                            label="First Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.first_name}
                            </Typography>
                            
                        </FormControl>

                        
                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="last_name">Last Name</InputLabel>
                            {(error !== '' && error.last_name) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            helperText={error.last_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="last_name"
                            type={'text'}
                            name="last_name"
                            label="Last Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.last_name}
                            </Typography>
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="phone_number">Phone</InputLabel>
                            {(error !== '' && error.phone_number) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="phone_number"
                            type={'tel'}
                            name="phone_number"
                            label="Phone"
                            helperText={error.phone_number}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="phone_number"
                            type={'tel'}
                            name="phone_number"
                            label="Phone"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.phone_number}
                            </Typography>
                            
                        </FormControl>


                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="residential_address">Residential Address</InputLabel>
                          {(error !== '' && error.residential_address) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="residential_address"
                          type={'text'}
                          name="residential_address"
                          label="Residential Address"
                          helperText={error.residential_address}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="residential_address"
                          type={'text'}
                          name="residential_address"
                          label="Residential Address"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.residential_address}
                          </Typography>
                      </FormControl>

                      <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="mothers_maiden_name">Mother's Maiden Name</InputLabel>
                            {(error !== '' && error.mothers_maiden_name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="mothers_maiden_name"
                            name="mothers_maiden_name"
                            type={'text'}
                            label="Mother's Maiden Name"
                            helperText={error.mothers_maiden_name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="mothers_maiden_name"
                            name="mothers_maiden_name"
                            type={'text'}
                            label="Mother's Maiden Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.mothers_maiden_name}
                            </Typography>
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="date_of_birth">Date Of Birth</InputLabel>
                            {(error !== '' && error.date_of_birth) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="date_of_birth"
                            type={'date'}
                            name="date_of_birth"
                            label="Date Of Birth"
                            helperText={error.date_of_birth}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="date_of_birth"
                            type={'date'}
                            name="date_of_birth"
                            label="Date Of Birth"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.date_of_birth}
                            </Typography>
                            
                        </FormControl>

                        <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        {usCitizen &&
                        <Alert severity="info">
                            To Further Verify your identity as a US citizen we request you provide your ID.me details and SSN, kindly enter your ID.me username and password below or visit the  
                            <a href='https://www.id.me' target='_blank'>ID.me</a> website to proceed.
                        </Alert>
                        }  
                        </Typography>

                        {usCitizen&& 

                            <Fragment>
                            <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="username">ID.me username</InputLabel>
                            {(error !== '' && error.username) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="username"
                            name="username"
                            type={'text'}
                            label="ID.me username"
                            helperText={error.username}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="username"
                            name="username"
                            type={'text'}
                            label="ID.me username"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.username}
                            </Typography>

                            </FormControl>


                            <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                                <InputLabel htmlFor="password">ID.me Password</InputLabel>
                                {(error !== '' && error.password) ? 
                                <OutlinedInput
                                error
                                onChange={onChange}
                                id="password"
                                name="password"
                                type={'password'}
                                label="ID.me Password"
                                helperText={error.password}
                                />
                                :
                                <OutlinedInput
                                onChange={onChange}
                                id="password"
                                name="password"
                                type={'password'}
                                label="ID.me Password"
                                />
                                }
                                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                                {error !== '' && error.password}
                                </Typography>
                            </FormControl>

                            <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                                <InputLabel htmlFor="ssn">SSN</InputLabel>
                                {(error !== '' && error.ssn) ?

                                <OutlinedInput
                                onChange={onChange}
                                error
                                id="ssn"
                                type={'text'}
                                name="ssn"
                                label="SSN"
                                helperText={error.ssn}
                                />
                                :
                                <OutlinedInput
                                onChange={onChange}
                                id="ssn"
                                type={'text'}
                                name="ssn"
                                label="SSN"
                                />
                                }
                                <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                                {error !== '' && error.ssn}
                                </Typography>
                                
                            </FormControl>
                            </Fragment>
                        }

                        

                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                      
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Document Front View
                        </Typography>
                        <Box>
                        {FrontImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(FrontImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.front_document) ?

                            <OutlinedInput
                            onChange={onFileChange}
                            error
                            accept="image/*"
                            id="front_document"
                            type='file'
                            name="front_document"
                            helperText={error.front_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onFileChange}
                            id="front_document"
                            type='file'
                            name="front_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.front_document}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Document Back View
                        </Typography>
                        <Box>
                        {backImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(backImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.back_document) ?

                            <OutlinedInput
                            onChange={onBackFileChange}
                            error
                            accept="image/*"
                            id="back_document"
                            type='file'
                            name="back_document"
                            helperText={error.back_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onBackFileChange}
                            id="back_document"
                            type='file'
                            name="back_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.back_document}
                            </Typography>
                        </FormControl>
                        </Box>


                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            Hand Holding ID
                        </Typography>
                        <Box>
                        {handImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(handImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.hand_document) ?

                            <OutlinedInput
                            onChange={onHandFileChange}
                            error
                            accept="image/*"
                            id="hand_document"
                            type='file'
                            name="hand_document"
                            helperText={error.hand_document}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onHandFileChange}
                            id="hand_document"
                            type='file'
                            name="hand_document"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.hand_document}
                            </Typography>
                        </FormControl>
                        {usCitizen &&

                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            SSN FRONT IMAGE
                        </Typography>
                        <Box>
                        {ssnFrontImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(ssnFrontImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.ssn_front) ?

                            <OutlinedInput
                            onChange={onSsnFrontFileChange}
                            error
                            accept="image/*"
                            id="ssn_front"
                            type='file'
                            name="ssn_front"
                            helperText={error.ssn_front}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onSsnFrontFileChange}
                            id="ssn_front"
                            type='file'
                            name="ssn_front"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.ssn_front}
                            </Typography>
                        </FormControl>
                        }
                        </Box>

                            {usCitizen &&
                        <FormControl fullWidth={false} sx={{ m: 1, minWidth: 50 }}>
                        <Typography  sx={{  textAlign: 'center', fontSize: 16, fontWeight: 'bold'  }} variant="subtitle1" gutterBottom>
                            SSN BACK IMAGE
                        </Typography>
                        <Box>
                        {ssnBackImage.image !== '' &&
                            <img style={{width: "100px", height: "auto"}} src={URL.createObjectURL(ssnBackImage.image)} alt="product"/>
                        }
                        </Box>
                            {(error !== '' && error.ssn_back) ?

                            <OutlinedInput
                            onChange={onSsnBackFileChange}
                            error
                            accept="image/*"
                            id="ssn_back"
                            type='file'
                            name="ssn_back"
                            helperText={error.ssn_back}
                            />
                            :
                            <OutlinedInput
                            accept="image/*"
                            onChange={onSsnBackFileChange}
                            id="ssn_back"
                            type='file'
                            name="ssn_back"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.ssn_back}
                            </Typography>
                        </FormControl>
                        }
                    </CardContent>  



                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Submitting...' : 'Verify Now'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
        :
        <Typography>
            Loading...
        </Typography>
        
    }

    {beneficiaries !== null &&
    <ListBeneficiary verifications={beneficiaries} />
    }
    
    </div>
  )
}

export default KycVerification
